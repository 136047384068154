import React from 'react';
import { Input, Container, Row, Popover, PopoverBody, Button } from 'reactstrap';
import BuscadorCta from './BuscadorCta';

export default class Cuenta extends React.Component {
    constructor(p) {
        super(p);
        let cta1 = '';
        let cta2 = '';
        let cta3 = '';
        let cta4 = '';
        if(p.cuenta != undefined && p.cuenta != ''){
            cta1 = p.cuenta.substring(0,4); 
            cta2 = p.cuenta.substring(5,9);
            cta3 = p.cuenta.substring(10,14);
            cta4 = p.cuenta.substring(15,19);
        }
        this.state = {
            popoverCtaOpen:false,
            i: 1,
            cta1,
            cta2,
            cta3,
            cta4
        };
    }
    componentWillReceiveProps(p){
        let cta1 = this.state.cta1;
        let cta2 = this.state.cta2;
        let cta3 = this.state.cta3;
        let cta4 = this.state.cta4;
        if(p.cuenta !== undefined && p.cuenta !== ''){
            cta1 = p.cuenta.substring(0,4); 
            cta2 = p.cuenta.substring(5,9);
            cta3 = p.cuenta.substring(10,14);
            cta4 = p.cuenta.substring(15,19);
        }
        this.setState({
            popoverCtaOpen:false,
            cta1, 
            cta2,
            cta3,
            cta4,    
            i: 1
        });
    }
    componentDidMount(){
    }
    toggleCta = () => {
        this.setState({popoverCtaOpen: !this.state.popoverCtaOpen});
    }    
    onChange = e => {
        this.setState({ [e.target.name] : e.target.value });
    }
    onBlur = e => {
        this.procesa(e);
    }
    procesa = (e) =>{
        const campo = 'cta' + this.state.i;
        const valor = e.target.value.padStart(4,'0');
        if(valor === '0000'){
            if(this.state.i < 4)
                this.state.cta4 = '0000';
            if(this.state.i < 3)
                this.state.cta3 = '0000';
            if(this.state.i < 2)
                this.state.cta2 = '0000';
        }
        this.setState({ [campo] : valor });
    }
    ctaSel = (cta) =>{
        const c = cta.Cta;
        this.setState({ 
            cta1:c.substring(0,4), 
            cta2:c.substring(5,9),
            cta3:c.substring(10,14),
            cta4:c.substring(15,19)
        },() => this.finaliza());
    }
    finaliza = () =>{
        const sepa = this.props.separador === undefined ? '-' : this.props.separador;
        const c = this.state.cta1 + sepa + this.state.cta2 + sepa + this.state.cta3 + sepa + this.state.cta4.padStart(4,'0');
        this.props.fin(c);
        document.getElementById("cta1").focus();
    }
    onKeyPress = e => {
        if (e.which === 13){
            this.procesa(e);
            if(this.state.i == 1)
               document.getElementById("cta2").focus();
            if(this.state.i == 2)
               document.getElementById("cta3").focus();
            if(this.state.i == 3)
               document.getElementById("cta4").focus();
            if(this.state.i == 4)
               this.finaliza();
            this.state.i++;
        }
    }
    render() {
        const estilo = {width:'50px'};
        return (
            <Container>
                <Row>
                    <Input style={estilo} size="sm" type="text" name="cta1" id="cta1" maxlength="4" value={this.state.cta1} onChange={this.onChange} onKeyPress={this.onKeyPress} onFocus={(e) => { this.setState({ i: 1 }); e.target.select(); }} onBlur={this.onBlur} />&nbsp;
                    <Input style={estilo} size="sm" type="text" name="cta2" id="cta2" maxlength="4" value={this.state.cta2} onChange={this.onChange} onKeyPress={this.onKeyPress} onFocus={(e) => { this.setState({ i: 2 }); e.target.select(); }} onBlur={this.onBlur} />&nbsp;
                    <Input style={estilo} size="sm" type="text" name="cta3" id="cta3" maxlength="4" value={this.state.cta3} onChange={this.onChange} onKeyPress={this.onKeyPress} onFocus={(e) => { this.setState({ i: 3 }); e.target.select(); }} onBlur={this.onBlur} />&nbsp;
                    <Input style={estilo} size="sm" type="text" name="cta4" id="cta4" maxlength="4" value={this.state.cta4} onChange={this.onChange} onKeyPress={this.onKeyPress} onFocus={(e) => { this.setState({ i: 4 }); e.target.select(); }} onBlur={this.onBlur} />&nbsp;
                    <Button size="sm" color="success" id="btnBuscarCta">
                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                    </Button>
                    <Popover trigger="legacy" placement="right" isOpen={this.state.popoverCtaOpen} target="btnBuscarCta" toggle={this.toggleCta} >
                        <PopoverBody>
                            <BuscadorCta onSelect={this.ctaSel} url={this.props.urlws + "/cuenta/cuentas?emp=" + this.props.emp + "&filtro="} />
                        </PopoverBody>
                    </Popover>
                </Row>
            </Container>
       )
    }
}