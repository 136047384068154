import React from 'react';
import { Row,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactDataGrid from 'react-data-grid';
import Cuenta from '../Cuenta';

const DecimalFormatter = ({ value }) => {
    if(value === 0)
       return <div>&nbsp;&nbsp;&nbsp;</div>;
    return <div className="celdaNumero">{value.formato()}&nbsp;</div>;
};
const DecimalFormatterTot = ({ value }) => {
    if(value === 0)
       return <div>&nbsp;&nbsp;&nbsp;</div>;
    return <div className="celdaNumero"><b>{value.formato()}</b>&nbsp;</div>;
};
const RowRenderer = ({renderBaseRow, ...canvasProps}) => {
    const fondo = canvasProps.idx % 2 ? "fondoBlanco" : "fondoGris";
    return <div className={fondo}><ReactDataGrid.Row ref={renderBaseRow} {...canvasProps}/></div>
}
  
export default class Polizas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            detalle:false,
            empresa:1,
            ejercicio:2019,
            periodo:1,
            tipo : 'D',
            fechaIni:fecIni,
            fechaFin:fecFin,
            nueva:false,
            empresas:[],
            ejercicios:[],
            periodos:[],
            tipos:[],
            polizas:[],
            objPoliza:{
                Detalle:[]
            }
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresas`;
        let resp = await fetch(url);
        const empresas = await resp.json();
        url = `${this.props.urlws}/home/ejercicios`;
        resp = await fetch(url);
        const ejercicios = await resp.json();
        url = `${this.props.urlws}/poliza/tipos`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/home/periodos`;
        resp = await fetch(url);
        const periodos = await resp.json();
        this.setState({cargando:false, empresas, ejercicios,tipos,periodos });
    }
    cargarMovs = async () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/poliza/polizas?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}`;
        let resp = await fetch(url);
        const polizas = await resp.json();
        this.setState({cargando:false, polizas });
    }
    cargarMov = async (mov) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/poliza/poliza?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}&pol=${mov.P}`;
        let resp = await fetch(url);
        const objPoliza = await resp.json();
        this.setState({cargando:false, objPoliza, detalle:true });
        console.log(objPoliza);
    }
    nuevoMov = () =>{
        const pol = {
            P : 0
        };
        this.cargarMov(pol);
    }

    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    imprimir = (mov) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        let params = `?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}&pol=${mov.P}`;
        let url = this.props.urlws + '/reporte/poliza' + params;
        window.open(url, '', '');
    }
    editarMov = (mov) =>{
        this.cargarMov(mov);
    }
    cancelar = () =>{
        this.setState({ detalle:false});
    }
    render(){
        const estilo1 = { display: this.state.detalle ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.detalle ? 'none' : 'inline' };
        return (
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                <span></span>
                }
                <div style={estilo1}>
                    <Captura poliza={this.state.objPoliza} height={450} urlws={this.props.urlws} emp={this.state.empresa} cancelar={this.cancelar} guardar={this.guardar} />
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup>
                                <span>Empresa</span><br />
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Ejercicio</span><br />
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Periodo</span><br />
                                <Input size="sm" type="select" name="periodo" value={this.state.periodo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br />
                                <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="X">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.polizas} height={500} width={990} onRowClick={this.editarMov} imprimir={this.imprimir}  />
               </div>
            </Container>
        );
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <React.Fragment>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>Poliza</th>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} detalle={this.props.detalle} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.P}</td>
            <td>{mov.F}</td>
            <td>{mov.C}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        let numero = props.poliza.Tipo + '-' + props.poliza.Num;
        if(props.poliza.Num === 0)
           numero = "NUEVA";
        this.state = {
            numero,
            mostrarModal:false,
            cargando:false,
            editando:false,
            renEdit:0,
            cambio:false,
            concepto :'',
            fecha:fec,
            ren:0,
            cta:'',
            nom:'',
            cuenta:{
                Nombre:''
            },
            conmov:'',
            cargo:'',
            abono:'',
            detalle:props.poliza.Detalle,
            totales:[
                {
                Car:0,
                Abo:0
                }
            ]
        };
    }
    componentWillReceiveProps(p){
        let car = 0;
        let abo = 0;
        let numero = p.poliza.Tipo + '-' + p.poliza.Num;
        if(p.poliza.Num === 0)
           numero = "NUEVA";
        const totales = [{Car:0, Abo:0}];
        for(let k = 0; k < p.poliza.Detalle.length; k++){
            car += p.poliza.Detalle[k].Car;
            abo += p.poliza.Detalle[k].Abo;
        }
        totales[0].Car = car;
        totales[0].Abo = abo;
        this.setState({
            numero,
            mostrarModal:false,
            cargando:false,
            popoverCtaOpen:false,
            editando:false,
            renEdit:0,
            detalle:p.poliza.Detalle,
            cambio:false,
            ren:0,
            cta:'',
            nom:'',
            concepto:p.poliza.Con,
            fecha:p.poliza.Fec,
            totales
        });
    }
    onChange = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }
    onGridKeyDown = (e) =>{
        console.log(e.which + ' - ' + this.state.ren);
        if(e.which == 13)
           this.editar();
        if(e.which == 45)
           this.insertar();
        if(e.which == 46)
           this.eliminar();
      }
      onCellSelected = (e) =>{
        this.state.ren = e.rowIdx;
      }
    insertar = () =>{
        this.state.editando = false;
        this.state.renEdit = this.state.ren;
        this.setState({mostrarModal:true, editando:false})
    }
    editar = () =>{
        const i = this.state.ren;
        this.state.conmov = this.state.detalle[i].Con;
        this.state.cta = this.state.detalle[i].Cta;
        this.state.cuenta.Cuenta = this.state.cta;
        this.state.cuenta.Nombre = this.state.detalle[i].Nom;
        this.state.cargo = this.state.detalle[i].Car;
        this.state.abono = this.state.detalle[i].Abo;
        this.state.editando = true;
        this.state.renEdit = i;
        this.setState({mostrarModal:true})
    }
    handleClose= () =>{
        this.setState({mostrarModal:false})
    }
    cargarCuenta = async (cta) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cuenta/cuenta?emp=${this.props.emp}&cta=${cta}`;
        const resp = await fetch(url);
        const cuenta = await resp.json();
        if(cuenta.Empresa == 0){
            Swal.fire('Atencion',"La cuenta " + cta + " no existe",'error');
            return;
        }
        if(!cuenta.Permite){
            Swal.fire('Atencion',"La cuenta " + cta +" no permite movimientos",'error');
            return;
        }
         this.setState({
            cargando:false,
            cuenta
        });
        document.getElementById("conmov").focus();
    }
    agregar = () =>{
        if(this.state.editando){
            this.actualizar();
            return;
        }
        let cargo = 0;
        let abono = 0;
        let tipo = 'C';
        let {totales} = this.state;

        if(this.state.cargo != '')
           cargo = Number(this.state.cargo);
        else{
           abono = Number(this.state.abono);
           tipo = 'A';
        }
        totales[0].Car += cargo;
        totales[0].Abo += abono;
        const r = {
            Cta: this.state.cuenta.Cuenta,
            Nom:this.state.cuenta.Nombre,
            Con:this.state.conmov,
            Car:cargo,
            Abo:abono,
            Tipo:tipo
        };
        this.state.detalle.push(r);
        this.setState({totales, ren:this.state.detalle.length},()=> console.log(this.state.totales));
    }
    actualizar = () =>{
        const i = this.state.renEdit;
        let cargo = 0;
        let abono = 0;
        let tipo = 'C';
        if(this.state.cargo != '')
           cargo = Number(this.state.cargo);
        else{
           abono = Number(this.state.abono);
           tipo = 'A';
        }
        this.state.detalle[i].Cta = this.state.cuenta.Cuenta;
        this.state.detalle[i].Nom = this.state.cuenta.Nombre;
        this.state.detalle[i].Con = this.state.conmov;
        this.state.detalle[i].Car = cargo;
        this.state.detalle[i].Abo = abono;
        this.state.detalle[i].Tipo = tipo;
        this.editando = false;
        this.setState({ren:i, mostrarModal : false},()=> console.log(this.state.totales));
    }
    
    onKeyPress = e => {
        if (e.which === 13){
            this.agregar();
        }
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }

    guardarB = async (obj) =>{
        const mov = {
            Emp : this.props.poliza.Emp,
            Ejer : this.props.poliza.Ejer,
            Per : this.props.poliza.Per,
            Tipo : this.props.poliza.Tipo,
            Num : this.props.poliza.Num,
            Fec : this.state.fecha,
            Con : this.state.concepto,
            For : this.props.poliza.For,
            Rev : this.props.poliza.Rev,
            Aut : this.props.poliza.Aut,
            S: JSON.stringify(this.state.detalle)
        };
        const url = this.props.urlws +"/poliza/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,2) !== "Er"){
            Swal.fire('Atencion','Se guardo la poliza ' + this.props.poliza.Tipo + '-' + res,'success');
            this.props.cancelar();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render(){
        const columns = [
            { 
              key: 'Cta', name: 'Cuenta' ,width:140,
              events: {
                onKeyDown: function(ev, args) {
                  console.log(
                    "The user entered edit mode on title column with rowIdx: " +
                      args.rowIdx +
                      " & rowId: " +
                      args.rowId
                  );
                }        }
            },
            { key: 'Nom', name: 'Nombre' ,width:350},
            { key: 'Con', name: 'Concepto' ,width:480},
            { key: 'Car', name: 'Cargo',formatter:DecimalFormatter ,width:100},
            { key: 'Abo', name: 'Abono' ,formatter:DecimalFormatter,width:100}];

            const columnsTot = [
                { key: 'Cta', name: '' ,width:120},
                { key: 'Nom', name: '' ,width:350},
                { key: 'Con', name: '' ,width:500},
                { key: 'Car', name: '',formatter:DecimalFormatterTot ,width:100},
                { key: 'Abo', name: '',formatter:DecimalFormatterTot,width:100}
            ];
    
        const rows = this.state.detalle;// this.props.poliza.Detalle; //[{id: 0, title: 'row1', count: 20}, {id: 1, title: 'row1', count: 40}, {id: 2, title: 'row1', count: 60}];
        const rowsTot = this.state.totales; //[{id: 0, title: 'row1', count: 20}, {id: 1, title: 'row1', count: 40}, {id: 2, title: 'row1', count: 60}];
        return (
            <div>
                                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }

                <div className="row">
                    <div className="col-1">
                        <span>Poliza</span><br />
                        <Input size="sm" type="text" value={this.state.numero}  />
                    </div>
                    <div className="col-2">
                        <span>Fecha</span><br />
                        <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                    </div>
                    <div className="col-9">
                        <span>Concepto</span><br />
                        <Input size="sm" type="text" name="concepto" value={this.state.concepto} onChange={this.onChange} />
                    </div>
                </div>
                <br/> 
                <ReactDataGrid
                    rowRenderer={RowRenderer}
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    headerRowHeight={30}
                    rowHeight={20}
                    headerFiltersHeight={0}
                    minHeight={400}
                    minWidth={1200}
                    onGridKeyDown={this.onGridKeyDown}
                    onCellSelected={this.onCellSelected}
                />
                <ReactDataGrid
                    rowRenderer={RowRenderer}
                    columns={columnsTot}
                    rowGetter={i => rowsTot[i]}
                    rowsCount={rowsTot.length}
                    headerRowHeight={1}
                    rowHeight={20}
                    headerFiltersHeight={1}
                    minHeight={30}
                    minWidth={1200}
                />
                <br/>
                <div className="col">
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.insertar}>+</Button>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose}>
                    <ModalBody>
                        <Container>
                            <Row>
                                <div>
                                    <Cuenta fin={this.cargarCuenta} emp={this.props.emp} cuenta={this.state.cta} nombre={this.state.nom} urlws={this.props.urlws}/>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <FormGroup>
                                        <span>Nombre</span><br />
                                        <Input size="sm" type="text" value={this.state.cuenta.Nombre} />
                                    </FormGroup>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <FormGroup>
                                        <span>Concepto</span><br />
                                        <Input size="sm" type="text" id="conmov" name="conmov" value={this.state.conmov} onChange={this.onChange} />
                                    </FormGroup>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-4">
                                    <FormGroup>
                                        <span>Cargo</span><br />
                                        <Input size="sm" type="text" name="cargo" value={this.state.cargo} onChange={this.onChange} />
                                    </FormGroup>
                                </div>
                                <div className="col-4">
                                    <FormGroup>
                                        <span>Abono</span><br />
                                        <Input size="sm" type="text" name="abono" value={this.state.abono} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                                    </FormGroup>
                                </div>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        );     
    }
}