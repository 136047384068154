import React from 'react';
import { Col,Row,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Popover, PopoverBody, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import Cuenta from '../Cuenta';

export default class Auxiliar extends React.Component {
    constructor(p){
        super(p);
        this.state = {
            cargando:false,
            empresa:1,
            ejercicio:2019,
            perIni:1,
            perFin:1,
            cc:0,
            ejercicios:[],
            periodos:[],
            cta:'',
            nom:'',
            cuenta:{
                Nombre:''
            },
            movs:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        let url = `${this.props.urlws}/home/ejercicios`;
        let resp = await fetch(url);
        const ejercicios = await resp.json();
        url = `${this.props.urlws}/home/periodos`;
        resp = await fetch(url);
        const periodos = await resp.json();
        this.setState({ejercicios,periodos});
    }
    cargarMovs = async () => {
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const perIni = this.state.perIni;
        const perFin = this.state.perFin;
        const cta = this.state.cuenta.Cuenta;
        const cc = this.state.cc;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/cuenta/auxiliar?emp=${emp}&ejer=${ejer}&perIni=${perIni}&perFin=${perFin}&cta=${cta}&cc=${cc}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false,movs});
    }
    cargarCuenta = async (cta) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cuenta/cuenta?emp=${this.props.emp}&cta=${cta}`;
        const resp = await fetch(url);
        const cuenta = await resp.json();
        if(cuenta.Empresa == 0){
            Swal.fire('Atencion',"La cuenta " + cta + " no existe",'error');
            return;
        }
         this.setState({
            cargando:false,
            cuenta
        });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <br/>
                <Row>
                    <Col-3>
                        <Cuenta fin={this.cargarCuenta} emp={this.props.emp} urlws={this.props.urlws}/>
                    </Col-3>
                    <Col>
                        <Input size="sm" type="text" value={this.state.cuenta.Nombre} />
                    </Col>
                </Row>
                <div className="row">
                    <div className="col-1">
                        <span>Ejercicio</span>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                <option key="0" value="">Especifique</option>
                                {this.state.ejercicios.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-1" >
                        <span>Del periodo</span>
                    </div>
                    <div className="col-3">
                            <FormGroup>
                                <Input size="sm" type="select" name="perIni" value={this.state.perIni} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-1">
                        <span>al periodo</span>
                    </div>
                    <div className="col-3">
                            <FormGroup>
                                <Input size="sm" type="select" name="perFin" value={this.state.perFin} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                          <Button size="sm" color="success" onClick={this.cargarMovs}>
                            <img src="/imagenes/ok.png" alt="aceptar" title="aceptar"/>
                            &nbsp; Aceptar
                        </Button>  
                        </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: 500, width:'99%', overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:40}}>Per</th>
                        <th style={{width:60}}>Pol</th>
                        <th style={{width:80}}>Fecha</th>
                        <th >Cuenta</th>
                        <th >Concepto</th>
                        <th className="numero" style={{width:85}}>Cargos</th>
                        <th className="numero" style={{width:85}}>Abonos</th>
                    </tr>
                </thead>
                <tbody>
                            {this.state.movs.map((mov, i) => (
                                <tr>
                                    <td>{mov.Per}</td>
                                    <td>{mov.Pol}</td>
                                    <td>{mov.Fec}</td>
                                    <td>{mov.Cta}</td>
                                    <td>{mov.Con}</td>
                                    <td className="numero">
                                        {mov.T === "C" && mov.Imp.formato()}
                                    </td>
                                    <td className="numero">
                                    {mov.T !== "C" && mov.Imp.formato()}
                                    </td>
                                </tr>
                            ))}
                </tbody>
                </Table>
            </div>
            </Container>
        );
    }
}
