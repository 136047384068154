import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ContaRepos from './ContaRepos';

export default class Menu extends React.Component {
    render() {
        
        let catConta = [{id:'catCtas',nombre:'Cuentas'},{id:'contaCostos',nombre:'Centros de costos'}];
        let movConta = [{id:'polizas',nombre:'Polizas'},{id:'cheques',nombre:'Cheques'},{id:'dividendos',nombre:'Retenciones de Dividendos'}];
        let repConta = [{id:'rptBalanza',nombre:'Balanza de comprobacion'},{id:'rptAnalitica',nombre:'Analitica de saldos'},{id:'rptAuxiliar',nombre:'Auxiliar'},{id:'rptVerificador',nombre:'Verificador de polizas'},{id:'rptPolizas',nombre:'Impresion de polizas'},{id:'rptBalance',nombre:'Balance General'},{id:'rptResultados',nombre:'Estado de resultados'}];
        let conConta = [{id:'conAuxi',nombre:'Auxiliar de cuenta'},{id:'conAna',nombre:'Analitica de saldos'}];

        let modulos = [
                       {id:"con100", nombre:"Catalogos", opciones:catConta},
                       {id:"con200", nombre:"Movimientos", opciones:movConta},
                       {id:"con300", nombre:"Consultas", opciones:conConta},
                       {id:"con400", nombre:"Reportes", opciones:repConta},
                       {id:"con500", nombre:"Procesos", opciones:[]}
                    ];
                        
        return (
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li className="nav-item">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
            </ul>)
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const opciones = modulo.opciones;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <ul>
                            {opciones.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}